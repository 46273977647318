<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">Password reset successful. Please login in app using new password</h2>
          <!-- <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <vs-button
                    class="w-full sm:w-auto"
                    to="/admin/login"
                  >Login</vs-button>
                </div> -->
        </div> 
      </vx-card>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      value1: ''
    }
  }
}

</script>

